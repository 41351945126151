@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #f5f5f5;
  height: 60px;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.navbar-logo img {
  height: 40px;
}

.navbar-links {
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links a {
  color: #262626;
  text-decoration: none;
  font-weight: 500;
  font-family: Poppins, sans-serif;
}

.navbar-links a:hover {
  color: #373737;
}

.navbar-toggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
  }

  .navbar-links {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
    z-index: 999;
  }

  .navbar-toggle {
    display: flex;
  }

  .navbar.active .navbar-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: #333;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
  }

  .navbar.active .navbar-links li {
    margin: 20px 0;
  }
}

.banner {
  width: 100%;
  height: max-content;
  align-self: center; 

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.banner-text {
  color: #414d64; 
  font-size: 48px; 
  font-weight: 500; 
  width: 45%;
  text-align: left;
  margin-left: 5%;
  font-family: Poppins, sans-serif;
}


.promotion-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: max-content;
  margin-left: auto;
  margin-right: 40%;
}

.promo-text {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #ff5705;
}

.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #25D366; 
  color: white;
  font-size: 18px;
  font-weight: 500;
  border: none;
  border-radius: 25px; 
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #1ebe5b; 
}

.whatsapp-icon {
  margin-right: 10px;
  font-size: 20px; 
}

.contact-banner {
  display: block;
  width: 100%;
  background-image: url('./assets/contact.png');
  background-size: cover;
  position: relative;
  height: 335px;
}

.contact-banner-container {
  max-width: 1320px;
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: center;
  padding: 100px 0;
}

.contact-banner .contact-banner-container h1{
  display: inline-block;
  font-size: 54px;
  font-weight: 600;
  color: #fff;
  font-family: Poppins, sans-serif;
}

.form-group {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
}

.contact-form-label {
  font-size: 14px;
  color: #414d64;
  width: 100%;
  margin-bottom: 4px;
  font-family: Poppins, sans-serif;
}

.contact-form-input {
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid #d9dbe0;
  padding: 0 16px;
  color: #414d64;
  font-size: 16px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.submit-btn {
  display: inline-block;
  background: #6041ad;
  border: 2px solid transparent;
  color: #fff;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 14px 40px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease 0.5s;
  margin-bottom: 20%;
}

.submit-btn:hover {
  display: inline-block;
  background: transparent;
  border: 2px solid #6041ad;
  color: #6041ad;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding: 14px 40px;
  border-radius: 8px;
  cursor: pointer;
  transition: all ease 0.5s;
}

.about-banner {
  display: block;
  width: 100%;
  background-image: url('./assets/about-us-banner.png');
  background-size: cover;
  position: relative;
  height: 335px;
}

.about-banner-container {
  max-width: 1320px;
  margin: 0 auto;
  display: block;
  width: 100%;
  text-align: center;
  padding: 100px 0;
}

.about-banner .about-banner-container h1{
  display: inline-block;
  font-size: 54px;
  font-weight: 600;
  color: #fff;
  font-family: Poppins, sans-serif;
}

.footer {
  display: block;
  width: 100%;
  background: #f5f5f5;
  padding-top: 25px;

}

.footer .footer-container {
  max-width: 960px;
  margin: 0 auto;
}

.footer .footer-container .footer-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  flex-direction: row;
}

.footer .footer-container .links-area {
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
}

.footer .footer-container .links-area .footer-links {
  display: inline-block;
  border-right: 2px solid #fff;
  margin-right: 20px;
}

.footer .footer-container .links-area .footer-links ul li a {
  display: inline-block;
  color: #373737;;
  font-weight: 400;
  transition: all ease 0.5s;
  width: 100%;
  line-height: 24px;
  font-family: Poppins, sans-serif;
  text-decoration: none;
}


@media (max-width: 1024px) {
  .banner {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .banner-text {
    width: 90%;
    margin: 0 auto 30px;
    text-align: center;
  }

  .promotion-container {
    margin: 30px auto;
  }

  .banner-text div:first-child {
    font-size: 36px;
  }

  .banner-text div:nth-child(2) {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .banner-text div:first-child {
    font-size: 28px;
  }

  .banner-text div:nth-child(2) {
    font-size: 16px;
  }

  .promo-text {
    font-size: 18px;
  }

  .whatsapp-button {
    font-size: 16px;
    padding: 8px 16px;
  }

  ul li {
    width: 100% !important;
    margin-bottom: 30px;
  }

  ul li p {
    padding: 0 20px !important;
  }
}

@media (max-width: 480px) {
  .banner-text div:first-child {
    font-size: 20px;
  }

  .banner-text div:nth-child(2) {
    font-size: 14px;
  }

  .promo-text {
    font-size: 16px;
  }

  .whatsapp-button {
    font-size: 14px;
    padding: 6px 12px;
  }
}


.navbar {
  position: relative;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-toggle {
  display: none;
}

@media screen and (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    background-color: #f5f5f5;
    padding: 20px 0;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-links li {
    margin: 10px 0;
  }

  .navbar-toggle {
    display: block;
    cursor: pointer;
  }

  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    transition: all 0.3s ease-in-out;
    background-color: #262626;
  }

  .navbar-toggle.active .bar:nth-child(2) {
    opacity: 0;
  }

  .navbar-toggle.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .navbar-toggle.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .whatsapp-button {
    margin-top: 10px;
  }
}


.contact-us-section {
  padding: 40px 20px;
}

@media screen and (max-width: 768px) {
  .contact-banner .contact-banner-container h1 {
    font-size: 40px;
  }

  .contact-us-section {
    padding: 20px 10px;
  }

  .contact-us-section > div:first-child {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-us-section > div:first-child > div:first-child {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .contact-us-section > div:first-child > div:last-child {
    display: none;
  }

  .contact-us-section > div:nth-child(2) {
    flex-direction: column;
  }

  .contact-us-section > div:nth-child(2) > div:first-child {
    margin-bottom: 30px;
  }

  .contact-us-section > div:nth-child(2) > div:last-child {
    width: 100%;
    padding-right: 0;
  }
}

@media screen and (max-width: 480px) {
  .contact-banner .contact-banner-container h1 {
    font-size: 32px;
  }

  .contact-us-section > div:first-child > div:first-child h1 {
    font-size: 32px;
  }

  .contact-us-section p, .contact-us-section a {
    font-size: 16px;
  }

  .contact-form-input, .contact-form-label {
    font-size: 14px;
  }

  .submit-btn {
    width: 100%;
  }
}


.footer {
  background: #f5f5f5;
  padding: 20px 0;
  border-top: 1px solid #e0e0e0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-logo img {
  height: 40px;
  width: auto;
}

.footer-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.footer-nav li {
  margin-left: 20px;
}

.footer-nav a {
  color: #373737;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  transition: color 0.3s ease;
}

.footer-nav a:hover {
  color: #6041b0;
}

@media screen and (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    margin-bottom: 20px;
  }

  .footer-nav ul {
    flex-direction: column;
    align-items: center;
  }

  .footer-nav li {
    margin: 10px 0;
  }
}